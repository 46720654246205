import React from "react";
import PropTypes from "prop-types";
import InputMask from "react-input-mask";
import NumberFormat from "react-number-format";
import { HiExclamationCircle as ErrorIcon } from "react-icons/hi";

function Input({ mask, label, fullWidth, error, helperText = "", ...props }) {
  const additionalClasses = ["disabled:bg-gray-300 disabled:text-gray-500"];
  if (fullWidth) additionalClasses.push("w-full");

  const classes = {
    default:
      "rounded border-gray-400 border p-2 outline-none focus:border-djp-biru-100 ",
    error:
      "rounded border-red-400 border p-2 outline-none focus:border-djp-biru-100",
  };

  const currentClasses = error ? classes.error : classes.default;

  const classesForHelper = {
    default: "text-gray-600 my-2",
    error: "text-red-400 my-2",
  };
  let inputComponent;
  if (mask === "npwp") {
    inputComponent = (
      <InputMask
        className={currentClasses + " " + additionalClasses.join(" ")}
        {...props}
        mask="99.999.999.9-999.999"
      />
    );
  } else if (mask === "rp") {
    inputComponent = (
      <NumberFormat
        thousandSeparator="."
        decimalSeparator=","
        className={currentClasses + " " + additionalClasses.join(" ")}
        prefix="Rp"
        {...props}
      />
    );
  } else {
    inputComponent = (
      <input
        type="text"
        className={currentClasses + " " + additionalClasses.join(" ")}
        {...props}
      />
    );
  }

  return (
    <>
      <div className="my-2">
        <label className={error && "text-red-700"}>{label}</label>
      </div>
      {helperText && (
        <div
          className={error ? classesForHelper.error : classesForHelper.default}
        >
          <div className="flex items-center">
            {error && <ErrorIcon className="mr-1" />}
            <span>{helperText}</span>
          </div>
        </div>
      )}

      <div>{inputComponent}</div>
    </>
  );
}

Input.defaultProps = {
  label: "",
  fullWidth: false,
  mask: "",
  disabled: false,
};

Input.propTypes = {
  /** Label teks */
  label: PropTypes.string.isRequired,

  /** Jika true, input akan memenuhi lebar container */
  fullWidth: PropTypes.bool,

  mask: PropTypes.oneOf(["npwp", "rp", ""]),

  error: PropTypes.bool,

  helperText: PropTypes.string,

  disabled: PropTypes.bool,
};

export default Input;

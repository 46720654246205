import React, { useState } from "react";
import TopMenu from "./TopMenu";
import logo from "./../../assets/image/logo-djp.svg";
import MobileSearchForm from "./MobileSearchForm";
import {
  AiOutlineCaretDown as CaretDownIcon,
  AiOutlineCaretLeft as CaretLeftIcon,
} from "react-icons/ai";

import { FaBars as MenuIcon } from "react-icons/fa";
import HeaderUserInfo from "./HeaderUserInfo";
import MobileUserInfo from "./MobileUserInfo";
import TabNav from "./TabNav";
import TabContent from "./TabContent";
import Footer from "./Footer";

export default function Djpappcontainer(props) {
  return (
    <>
      <TopMenu />
      <Header {...props} />
    </>
  );
}

function Header(props) {
  const [showMenu, setShowMenu] = useState(false);

  const isAppMode = props.appMode;

  const DEFAULT_MENU = props.defaultMenu || [
    { label: "Beranda", href: "http://www.pajak.go.id/id", active: true },
    { label: "Badan", href: "http://www.pajak.go.id/id/index-badan" },
    {
      label: "Orang Pribadi",
      subMenu: [
        { label: "Karyawan", href: "http://www.pajak.go.id/id/index-karyawan" },
        {
          label: "Melakukan Pekerjaan Bebas",
          href: "http://www.pajak.go.id/id/index-bebas",
        },
      ],
    },
    { label: "Bendahara", href: "http://www.pajak.go.id/id/index-bendahara" },
    { label: "Konsultan", href: "http://www.pajak.go.id/id/index-konsultan" },
    { label: "PJAP", href: "http://www.pajak.go.id/id/index-pjap" },
  ];
  const menu = props.menu || DEFAULT_MENU;

  return (
    <>
      <div className="header">
        <div className="container container-djp flex items-center justify-between w-full">
          <div className="logo-container">
            <a href="https://djponline.pajak.go.id/">
              <img src={logo} alt="logo" className="logo" />
            </a>
          </div>
          {props.user && <HeaderUserInfo user={props.user} />}
          {!isAppMode && !props.user ? (
            <div className="flex flex-wrap lg:flex-row-reverse">
              {!props.user && <MobileSearchForm />}
              <div className="flex justify-items-center items-center">
                <div className="hidden lg:flex justify-items-center items-center relative">
                  {menu.map((link) => (
                    <Menu subMenu={link.subMenu} href={link.href}>
                      {link.label}
                    </Menu>
                  ))}
                </div>
                <div className="lg:hidden">
                  <button
                    className="focus:outline-none text-white bg-djp-biru-500 p-2 rounded text-md font-bold relative"
                    onClick={(e) => setShowMenu((state) => !state)}
                  >
                    <MenuIcon />
                  </button>
                </div>
              </div>
            </div>
          ) : (
              <div className="lg:hidden flex flex-row">
                <MobileSearchForm />
                <button
                  className="focus:outline-none text-white bg-djp-biru-500 p-2 rounded text-md font-bold relative"
                  onClick={(e) => setShowMenu((state) => !state)}
                >
                  <MenuIcon />
                </button>
              </div>
            )}
        </div>

        {showMenu && (
          <div className="absolute w-full z-50 bg-white">
            {menu.map((item) => {
              return <MobileMenuItem {...item} />;
            })}
          </div>
        )}
      </div>
      {props.user && <MobileUserInfo user={props.user} />}
      {
        isAppMode ? <div div className="my-4">
          <div className="container container-djp">
            <div className="hidden lg:block">
              {menu.map((item) => (
                <TabNav {...item} />
              ))}
            </div>
            <div>
              <TabContent>{props.children}</TabContent>
            </div>
          </div>
        </div>
          : props.children
      }
      <Footer />
    </>
  );
}

function MobileMenuItem(props) {
  const [showSubMenu, setShowSubMenu] = useState(false);

  return (
    <>
      <div className={`rounded font-medium uppercase w-full my-1 text-white p-2 ${props.active ? `bg-djp-biru-800` : `bg-djp-abu-800 `}`}>
        {props.subMenu && props.subMenu.length ? (
          <div
            className="flex flex-row justify-between items-center cursor-pointer"
            onClick={(e) => setShowSubMenu((state) => !state)}
          >
            {props.label}
            {showSubMenu ? <CaretLeftIcon /> : <CaretDownIcon />}
          </div>
        ) : (
            <a href={props.href}>
              <div className="flex flex-row justify-between items-center">
                {props.label}
              </div>
            </a>
          )}
      </div>
      {props.subMenu &&
        props.subMenu.length &&
        showSubMenu &&
        props.subMenu.map((subMenu) => (
          <a href={subMenu.href}>
            <div className="p-1 pl-8 uppercase text-xs">{subMenu.label}</div>
          </a>
        ))}
    </>
  );
}

function Menu(props) {
  const [isHover, setHover] = useState(false);
  const hasSubMenu = props.subMenu && props.subMenu.length;

  const subMenu = isHover && hasSubMenu && (
    <div className="absolute" style={{ width: "250px" }}>
      <div className="border-djp-biru-800 border-2 rounded-tr"></div>
      <div className="bg-white p-2 text-djp-abu-800 text-xs">
        {props.subMenu.map((e, index) => {
          return (
            <SubMenu last={index === props.subMenu.length - 1} href={e.href}>
              {e.label}
            </SubMenu>
          );
        })}
      </div>
    </div>
  );

  const className = [
    "rounded font-semibold bg-djp-abu-800 mr-1 hover:bg-djp-biru-800",
  ];

  if (hasSubMenu) className.push("hover:rounded-b-none");

  const Component =
    props.component || ((props) => <a {...props}>{props.children}</a>);

  return (
    <>
      <div
        className={className.join(" ")}
        onMouseEnter={(e) => setHover(true)}
        onMouseLeave={(e) => setHover(false)}
      >
        <div className="p-2 text-xs uppercase text-white flex flex-row items-center">
          <Component {...props} />
          {hasSubMenu && <CaretDownIcon className="ml-2" />}
        </div>
        {subMenu}
      </div>
    </>
  );
}

function SubMenu(props) {
  return (
    <>
      <a href={props.href}>
        <div className="py-2">{props.children}</div>
      </a>
      {!props.last && <hr />}
    </>
  );
}

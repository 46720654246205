import React from 'react'
import FormControl from '../djp/FormControl';
import { useParams } from "react-router-dom"
import { useQuery } from '../../services/useQuery';

export default function ErrorPage(props) {
  const { code } = useParams();
  const query = useQuery();
  const ref = query.get("ref");
  const clientUrl = ref && Buffer.from(ref, 'base64').toString().startsWith('http') ? Buffer.from(ref, 'base64').toString() : null;
  const href = ref && clientUrl ? clientUrl + `?error=${code}` : 'https://djponline.pajak.go.id';

  return (
    <div className="container mx-auto">
      <div className="border rounded-lg shadow-lg md:w-1/2 lg:w-1/3 mx-auto m-10 p-10">
        <h1 className="text-2xl font-semibold ">
          Telah Terjadi Kesalahan
                  </h1>
        <FormControl>
          <p>Telah terjadi kesalahan: {code.toUpperCase()}</p>
          <p className='mt-10'><a href={href} className="font-semibold text-djp-biru-800 underline">Kembali ke halaman penyedia aplikasi</a></p>
        </FormControl>
      </div>
    </div>
  )
}

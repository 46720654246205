import React, { useState } from "react";
import { FaUser as UserIcon } from "react-icons/fa";
import { RiAccountBoxLine as AccountIcon } from "react-icons/ri";
import { FiChevronRight as ChevronRightIcon } from "react-icons/fi";
export default function MobileUserInfo(props) {
  const [menuOpen, setMenuOpen] = useState(false);
  return (
    <div className="sticky bg-white lg:hidden w-full py-2" style={{top:"94px"}}>
      <div className="container container-djp flex justify-end items-center ">
      <button
        type="button"
        className="btn-user relative focus:outline-none"
        onClick={(e) => {
          setMenuOpen((state) => !state);
        }}
      >
        <div className="flex items-center">
          <span className="text-xs font-bold text-djp-kuning-500 mr-2">
            Halo,
          </span>
          <span className="text-xs font-bold text-djp-biru-800">
            {props.user.nama}
          </span>
          <span
            className="account icon rounded ml-2 text-md flex items-center justify-center"
            style={{ width: "30px", height: "30px" }}
          >
            <UserIcon />
          </span>
        </div>
      </button>
      {menuOpen && (
        <div
          className="account-container absolute block bg-white right-0"
          style={{ width: "280px" }}
        >
          <div
            className="account-top-part flex items-center bg-cover bg-no-repeat"
            style={{ padding: "10px 10px" }}
          >
            <span
              className="icon-top-part rounded ml-2 text-xl flex items-center justify-center"
              style={{ width: "40px", height: "40px" }}
            >
              <UserIcon />
            </span>
            <span className="name-top-part" style={{ fontSize: "0.9rem" }}>
              {props.user.nama}
            </span>
          </div>
          <div>
            <a
              href="https://account.pajak.go.id/profil"
              className="account-mid-part flex items-center hover:bg-gray-100"
              style={{ padding: "10px 18px" }}
            >
              <div className="icon-mid-part rounded text-2xl flex items-center justify-center">
                <AccountIcon />
              </div>
              <div
                className="flex flex-col items-start"
                style={{ minWidth: "12rem", fontSize: "0.8rem" }}
              >
                <div
                  className="font-medium"
                  style={{
                    color: "#6c7293",
                  }}
                >
                  Profil Saya
                </div>
                <div className="font-light" style={{ color: "#a7abc3" }}>
                  Pengaturan akun dan lainnya
                </div>
              </div>
              <div style={{ color: "#a7abc3" }}>
                <ChevronRightIcon />
              </div>
            </a>
            <div
              className="flex items-center"
              style={{ padding: "10px 18px", borderTop: "1px solid #f7f8fa" }}
            >
              <a
                href="/logout"
                className="btn-logout hover:text-white hover:bg-indigo-700"
              >
                Keluar
              </a>
            </div>
          </div>
        </div>
      )}
      </div>
    </div>
  );
}

import React from "react";
import kemenkeu from "./../../assets/image/kemenkeu.svg";
import kringpajak from "../../assets/image/kring-pajak.svg";
import situspajak from "../../assets/image/situs-pajak.svg";

export default function Footer() {
  const year = new Date().getFullYear();
  return (
    <>
      <div className="slogan">
        <div className="container container-djp">
          <h1 className="slogan-text">Pajak Kita, Untuk Kita</h1>
        </div>
      </div>
      <div className="footer">
        <div className="container container-djp">
          <div className="row">
            <div className="col-12">
              <ul className="nav">
                <li className="nav-item">
                  <a className="nav-link pranala" href="https://pajak.go.id/">
                    <h6>PRANALA</h6>
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="https://www.kemenkeu.go.id/">
                    <h6>Kementerian Keuangan</h6>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="https://www.kemenkeu.go.id/apbnkita"
                  >
                    <h6>APBN Kita</h6>
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="http://edukasi.pajak.go.id/">
                    <h6>Edukasi Pajak</h6>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="https://pajak.go.id/reformasi-perpajakan"
                  >
                    <h6>Reformasi Perpajakan</h6>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="https://pajak.go.id/prasyarat-pengguna"
                  >
                    <h6>Prasyarat</h6>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="https://pajak.go.id/hubungi-kami"
                  >
                    <h6>Hubungi Kami</h6>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="https://pajak.go.id/form/contact"
                  >
                    <h6>Kritik &amp; Saran</h6>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="row">
            <div className="col-4 mb-4">
              <a href="https://www.kemenkeu.go.id/">
                <img
                  src={kemenkeu}
                  alt="kemenkeu-logo"
                  className="kemenkeu-logo mb-4"
                />
              </a>
              <p className="alamat">
                Jalan Gatot Subroto, Kav. 40-42, Jakarta 12190 <br />
                Telp: (+62) 21 - 525 0208
              </p>
            </div>
            <div className="col-4 mb-4 flex items-center">
              <div className="sosmed">
                <h4>Ikuti Kami</h4>
                <ul className="social-icons">
                  <li className="social-icons-facebook">
                    <a
                      href="https://www.facebook.com/DitjenPajakRI/"
                      target="_blank"
                      title="Facebook"
                      rel="noopener noreferrer"
                    >
                      <span></span>
                    </a>
                  </li>
                  <li className="social-icons-twitter">
                    <a
                      href="https://www.twitter.com/DitjenPajakRI"
                      target="_blank"
                      title="Twitter"
                      rel="noopener noreferrer"
                    >
                      <span></span>
                    </a>
                  </li>
                  <li className="social-icons-youtube">
                    <a
                      href="https://www.youtube.com/user/DitjenPajakRI"
                      target="_blank"
                      title="Youtube"
                      rel="noopener noreferrer"
                    >
                      <span></span>
                    </a>
                  </li>
                  <li className="social-icons-instagram">
                    <a
                      href="https://www.instagram.com/ditjenpajakri/?hl=id"
                      target="_blank"
                      title="Instagram"
                      rel="noopener noreferrer"
                    >
                      <span></span>
                    </a>
                  </li>
                </ul>
                <h5 className="caption-sosmed">@DITJENPAJAKRI</h5>
              </div>
            </div>
            <div className="col-4 mb-4 flex items-center">
              <div className="flex flex-wrap w-full">
                <a className="w-1/2" href="https://pengaduan.pajak.go.id/">
                  <img
                    src={kringpajak}
                    alt="kringpajak-logo"
                    className="mr-4 w-full"
                  />
                </a>
                <a className="w-1/2" href="https://www.pajak.go.id/">
                  <img
                    src={situspajak}
                    alt="situspajak-logo"
                    className="w-full"
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <p className="copyright">
                Copyright &copy; {year} Direktorat Jenderal Pajak.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

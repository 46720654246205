import React, { useState } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import SearchForm from "./SearchForm";
import useAxios from "axios-hooks";
import MenuJsonProvider from "./MenuJsonProvider";

export default function TopMenu(props) {

  // TODO: Handle error
  const [{ data }] = useAxios(
    (process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_PROD_URL_CDN
      : process.env.REACT_APP_DEV_URL_CDN) + "/json/menu.json"
  );

  const _data = data || MenuJsonProvider;

  return <TopMenuContainer {...props} data={_data} />;
}

export function TopMenuContainer(props) {
  const { isLoggedin } = props;
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <div className="header-top">
      <div className="container container-djp flex justify-between w-full">
        <button
          className="btn-navbar lg:hidden block focus:outline-none"
          onClick={(e) => {
            setMenuOpen((state) => !state);
          }}
        >
          MENU
        </button>
        {menuOpen && (
          <div className="lg:hidden absolute rounded bg-djp-biru-800 z-30 px-2 w-64 top-menu-ddl">
            {props.data ? (
              mappingTop(props.data)
            ) : (
              <div className="py-3">
                <SkeletonTheme
                  color="rgba(221, 219, 221, 0.6)"
                  highlightColor="#eee"
                >
                  <Skeleton
                    count={5}
                    height={17.5}
                    style={{
                      marginRight: "15px",
                      marginBottom: "4px",
                    }}
                  ></Skeleton>
                </SkeletonTheme>
              </div>
            )}
          </div>
        )}
        <div className="navbar-top lg:block hidden">
          {props.data ? (
            mapping(props.data)
          ) : (
            <div>
              <SkeletonTheme
                color="rgba(221, 219, 221, 0.6)"
                highlightColor="#eee"
              >
                <Skeleton
                  count={5}
                  width={85}
                  height={17.5}
                  style={{
                    marginRight: "11px",
                    marginTop: "8px",
                    marginBottom: "11px",
                  }}
                ></Skeleton>
              </SkeletonTheme>
            </div>
          )}
        </div>
        <div className="flex flex-wrap">
          <div className="navbar-lang">
            <a href="/id">ID</a> | <a href="/en">EN</a>
          </div>
          {isLoggedin && <SearchForm />}
        </div>
      </div>
    </div>
  );
}

function mapping(menu, level = 1) {
  const className = level === 1 ? "list-menus" : "menu-items";
  const childClassName = level === 1 ? "list-item" : "menu";
  return (
    <ul className={className}>
      {menu.map((e, index) => {
        const children = e.has_children ? mapping(e.subtree, level + 1) : "";
        var _url = e.link.url;
        var strUrl = _url.replace(/\\/g, "");
        let url;
        if (strUrl.substr(0, 4) === "http") {
          url = strUrl;
        } else {
          url = "https://pajak.go.id" + strUrl;
        }
        return (
          <li key={index} className={childClassName}>
            <a href={url}>{e.link.title} </a>
            {children}
          </li>
        );
      })}
    </ul>
  );
}

function mappingTop(menu, level = 1) {
  const className = level === 1 ? "top-list-menus" : "top-menu-items";
  const childClassName = level === 1 ? "top-list-item" : "top-menu";
  return (
    <ul className={className}>
      {menu.map((e, index) => {
        const children = e.has_children ? mappingTop(e.subtree, level + 1) : "";
        var _url = e.link.url;
        var strUrl = _url.replace(/\\/g, "");
        let url;
        if (strUrl.substr(0, 4) === "http") {
          url = strUrl;
        } else {
          url = "https://pajak.go.id" + strUrl;
        }
        return (
          <li key={index} className={childClassName}>
            <a href={url}>{e.link.title} </a>
            {children}
          </li>
        );
      })}
    </ul>
  );
}

import React from "react";
import search from "./../../assets/image/icon/search.svg";

export default function SearchForm(props) {
  return (
    <div className="hidden lg:flex justify-items-center items-center">
      <div className="mainmenu-search">
        <form
          action="https://pajak.go.id/id/search/node"
          method="get"
          className="mainmenu-search__form"
        >
          <div className="input-group">
            <input
              type="text"
              className="mainmenu-search__input"
              name="keys"
              placeholder="Pencarian..."
              autoComplete="off"
            />
            <div className="input-group-prepend">
              <span className="input-group-text">
                <img src={search} alt="search" className="search" />
              </span>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

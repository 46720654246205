import React from "react";
import PropTypes from "prop-types";

function Button(props) {
  let className = "";
  if (props.primary)
    className =
      "bg-djp-biru-800 hover:bg-djp-biru-900 text-white rounded rounded-lg";
  else if (props.silent)
    className =
      "text-djp-biru-900 underline";
  else
    className =
      "text-djp-abu-800 bg-djp-abu-200 hover:bg-djp-abu-500 rounded rounded-lg border border-gray-500";

  if (props.size === "large") className = className + " text-lg px-6 py-4";
  else if (props.size === "small") className = className + " text-xs px-2 py-1";
  else className = className + " px-4 py-3";

  className = props.appendClass
    ? className + " " + props.appendClass
    : className;
  return (
    <button className={className} {...props}>
      {props.children}
    </button>
  );
}

Button.propTypes = {
  /** Gunakan warna Primary" */
  primary: PropTypes.bool,

  /** Ukuran Button*/
  size: PropTypes.oneOf(["large", "small"]),
  appendClass: PropTypes.string,
};

export default Button;

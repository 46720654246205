module.exports = [
  {
    link: {
      weight: "-50",
      title: "Profil",
      description: null,
      menu_name: "navigasi-kedua",
      provider: "menu_link_content",
      parent: "",
      enabled: true,
      expanded: true,
      resettable: false,
      translatable: true,
      deletable: true,
      route_name: "\u003Cfront\u003E",
      route_parameters: [],
      url: "/id",
      options: [],
      meta_data: { entity_id: "1" },
      delete_route: {},
      edit_route: {},
    },
    has_children: true,
    depth: 1,
    in_active_trail: false,
    subtree: [
      {
        link: {
          weight: "-50",
          title: "Overview",
          description: null,
          menu_name: "navigasi-kedua",
          provider: "menu_link_content",
          parent: "menu_link_content:6a625350-f16e-47b3-b5e5-4287cc34eae4",
          enabled: true,
          expanded: false,
          resettable: false,
          translatable: true,
          deletable: true,
          route_name: "entity.node.canonical",
          route_parameters: { node: "34213" },
          url: "/id/selayang-pandang",
          options: [],
          meta_data: { entity_id: "3" },
          delete_route: {},
          edit_route: {},
        },
        has_children: false,
        depth: 2,
        in_active_trail: false,
        subtree: [],
        count: 1,
      },
      {
        link: {
          weight: "-49",
          title: "Visi, Misi dan Maklumat Pelayanan",
          description: null,
          menu_name: "navigasi-kedua",
          provider: "menu_link_content",
          parent: "menu_link_content:6a625350-f16e-47b3-b5e5-4287cc34eae4",
          enabled: true,
          expanded: false,
          resettable: false,
          translatable: true,
          deletable: true,
          route_name: "entity.node.canonical",
          route_parameters: { node: "34201" },
          url: "/id/visi-dan-misi",
          options: [],
          meta_data: { entity_id: "2" },
          delete_route: {},
          edit_route: {},
        },
        has_children: false,
        depth: 2,
        in_active_trail: false,
        subtree: [],
        count: 1,
      },
      {
        link: {
          weight: "-48",
          title: "Tugas dan Fungsi",
          description: null,
          menu_name: "navigasi-kedua",
          provider: "menu_link_content",
          parent: "menu_link_content:6a625350-f16e-47b3-b5e5-4287cc34eae4",
          enabled: true,
          expanded: false,
          resettable: false,
          translatable: true,
          deletable: true,
          route_name: "entity.node.canonical",
          route_parameters: { node: "18178" },
          url: "/id/tugas-dan-fungsi-0",
          options: [],
          meta_data: { entity_id: "48" },
          delete_route: {},
          edit_route: {},
        },
        has_children: false,
        depth: 2,
        in_active_trail: false,
        subtree: [],
        count: 1,
      },
      {
        link: {
          weight: "-47",
          title: "Logo DJP",
          description: null,
          menu_name: "navigasi-kedua",
          provider: "menu_link_content",
          parent: "menu_link_content:6a625350-f16e-47b3-b5e5-4287cc34eae4",
          enabled: true,
          expanded: false,
          resettable: false,
          translatable: true,
          deletable: true,
          route_name: "entity.node.canonical",
          route_parameters: { node: "34351" },
          url: "/id/logo-direktorat-jenderal-pajak",
          options: [],
          meta_data: { entity_id: "42" },
          delete_route: {},
          edit_route: {},
        },
        has_children: false,
        depth: 2,
        in_active_trail: false,
        subtree: [],
        count: 1,
      },
      {
        link: {
          weight: "-46",
          title: "Struktur Organisasi",
          description: null,
          menu_name: "navigasi-kedua",
          provider: "menu_link_content",
          parent: "menu_link_content:6a625350-f16e-47b3-b5e5-4287cc34eae4",
          enabled: true,
          expanded: false,
          resettable: false,
          translatable: true,
          deletable: true,
          route_name: "entity.node.canonical",
          route_parameters: { node: "34214" },
          url: "/id/struktur-organisasi",
          options: [],
          meta_data: { entity_id: "5" },
          delete_route: {},
          edit_route: {},
        },
        has_children: false,
        depth: 2,
        in_active_trail: false,
        subtree: [],
        count: 1,
      },
      {
        link: {
          weight: "-45",
          title: "Daftar Pejabat",
          description: null,
          menu_name: "navigasi-kedua",
          provider: "menu_link_content",
          parent: "menu_link_content:6a625350-f16e-47b3-b5e5-4287cc34eae4",
          enabled: true,
          expanded: false,
          resettable: false,
          translatable: true,
          deletable: true,
          route_name: "view.daftar_pejabat_page.page_1",
          route_parameters: [],
          url: "/id/daftar-pejabat-page",
          options: { query: [] },
          meta_data: { entity_id: "4" },
          delete_route: {},
          edit_route: {},
        },
        has_children: false,
        depth: 2,
        in_active_trail: false,
        subtree: [],
        count: 1,
      },
      {
        link: {
          weight: "-44",
          title: "Unit Kerja",
          description: null,
          menu_name: "navigasi-kedua",
          provider: "menu_link_content",
          parent: "menu_link_content:6a625350-f16e-47b3-b5e5-4287cc34eae4",
          enabled: true,
          expanded: false,
          resettable: false,
          translatable: true,
          deletable: true,
          route_name: "view.daftar_unit_kerja_direktorat_jenderal_pajak.page_1",
          route_parameters: [],
          url: "/id/daftar-unit-kerja-direktorat-jenderal-pajak",
          options: { query: [] },
          meta_data: { entity_id: "6" },
          delete_route: {},
          edit_route: {},
        },
        has_children: false,
        depth: 2,
        in_active_trail: false,
        subtree: [],
        count: 1,
      },
      {
        link: {
          weight: "-43",
          title: "Survei Kepuasan",
          description: null,
          menu_name: "navigasi-kedua",
          provider: "menu_link_content",
          parent: "menu_link_content:6a625350-f16e-47b3-b5e5-4287cc34eae4",
          enabled: true,
          expanded: false,
          resettable: false,
          translatable: true,
          deletable: true,
          route_name: "entity.node.canonical",
          route_parameters: { node: "34564" },
          url: "/id/hasil-survei-kepuasan-2019",
          options: { query: [] },
          meta_data: { entity_id: "52" },
          delete_route: {},
          edit_route: {},
        },
        has_children: false,
        depth: 2,
        in_active_trail: false,
        subtree: [],
        count: 1,
      },
    ],
    count: 9,
  },
  {
    link: {
      weight: "-49",
      title: "Peraturan",
      description: null,
      menu_name: "navigasi-kedua",
      provider: "menu_link_content",
      parent: "",
      enabled: true,
      expanded: true,
      resettable: false,
      translatable: true,
      deletable: true,
      route_name: "\u003Cfront\u003E",
      route_parameters: [],
      url: "/id",
      options: [],
      meta_data: { entity_id: "7" },
      delete_route: {},
      edit_route: {},
    },
    has_children: true,
    depth: 1,
    in_active_trail: false,
    subtree: [
      {
        link: {
          weight: "-50",
          title: "Peraturan",
          description: null,
          menu_name: "navigasi-kedua",
          provider: "menu_link_content",
          parent: "menu_link_content:d1508f19-81f0-45b4-a780-54183d7cadab",
          enabled: true,
          expanded: false,
          resettable: false,
          translatable: true,
          deletable: true,
          route_name: "view.regulasi_page.page_1",
          route_parameters: [],
          url: "/id/regulasi-page",
          options: { query: [] },
          meta_data: { entity_id: "8" },
          delete_route: {},
          edit_route: {},
        },
        has_children: false,
        depth: 2,
        in_active_trail: false,
        subtree: [],
        count: 1,
      },
      {
        link: {
          weight: "-49",
          title: "Kurs",
          description: null,
          menu_name: "navigasi-kedua",
          provider: "menu_link_content",
          parent: "menu_link_content:d1508f19-81f0-45b4-a780-54183d7cadab",
          enabled: true,
          expanded: false,
          resettable: false,
          translatable: true,
          deletable: true,
          route_name: "",
          route_parameters: [],
          url: "http://www.fiskal.kemenkeu.go.id/dw-kurs-db.asp",
          options: { external: true },
          meta_data: { entity_id: "9" },
          delete_route: {},
          edit_route: {},
        },
        has_children: false,
        depth: 2,
        in_active_trail: false,
        subtree: [],
        count: 1,
      },
    ],
    count: 3,
  },
  {
    link: {
      weight: "-48",
      title: "Unduh",
      description: null,
      menu_name: "navigasi-kedua",
      provider: "menu_link_content",
      parent: "",
      enabled: true,
      expanded: true,
      resettable: false,
      translatable: true,
      deletable: true,
      route_name: "\u003Cfront\u003E",
      route_parameters: [],
      url: "/id",
      options: [],
      meta_data: { entity_id: "11" },
      delete_route: {},
      edit_route: {},
    },
    has_children: true,
    depth: 1,
    in_active_trail: false,
    subtree: [
      {
        link: {
          weight: "-50",
          title: "Aplikasi Perpajakan",
          description: null,
          menu_name: "navigasi-kedua",
          provider: "menu_link_content",
          parent: "menu_link_content:62b60cc1-dfe4-432e-a194-00c6b02c126f",
          enabled: true,
          expanded: false,
          resettable: false,
          translatable: true,
          deletable: true,
          route_name: "view.aplikasi_page.page_1",
          route_parameters: [],
          url: "/id/aplikasi-page",
          options: { query: [] },
          meta_data: { entity_id: "12" },
          delete_route: {},
          edit_route: {},
        },
        has_children: false,
        depth: 2,
        in_active_trail: false,
        subtree: [],
        count: 1,
      },
      {
        link: {
          weight: "-48",
          title: "Formulir Perpajakan",
          description: null,
          menu_name: "navigasi-kedua",
          provider: "menu_link_content",
          parent: "menu_link_content:62b60cc1-dfe4-432e-a194-00c6b02c126f",
          enabled: true,
          expanded: false,
          resettable: false,
          translatable: true,
          deletable: true,
          route_name: "view.formulir_perpajakan_page.page_1",
          route_parameters: [],
          url: "/id/formulir-page",
          options: { query: [] },
          meta_data: { entity_id: "13" },
          delete_route: {},
          edit_route: {},
        },
        has_children: false,
        depth: 2,
        in_active_trail: false,
        subtree: [],
        count: 1,
      },
    ],
    count: 3,
  },
  {
    link: {
      weight: "-47",
      title: "Informasi Publik",
      description: null,
      menu_name: "navigasi-kedua",
      provider: "menu_link_content",
      parent: "",
      enabled: true,
      expanded: true,
      resettable: false,
      translatable: true,
      deletable: true,
      route_name: "\u003Cfront\u003E",
      route_parameters: [],
      url: "/id",
      options: [],
      meta_data: { entity_id: "14" },
      delete_route: {},
      edit_route: {},
    },
    has_children: true,
    depth: 1,
    in_active_trail: false,
    subtree: [
      {
        link: {
          weight: "-50",
          title: "Daftar Informasi Publik",
          description: null,
          menu_name: "navigasi-kedua",
          provider: "menu_link_content",
          parent: "menu_link_content:addcb77a-f63f-4755-80cc-fad5c6f33fd8",
          enabled: true,
          expanded: true,
          resettable: false,
          translatable: true,
          deletable: true,
          route_name: "\u003Cfront\u003E",
          route_parameters: [],
          url: "/id",
          options: [],
          meta_data: { entity_id: "20" },
          delete_route: {},
          edit_route: {},
        },
        has_children: true,
        depth: 2,
        in_active_trail: false,
        subtree: [
          {
            link: {
              weight: "-50",
              title:
                "Daftar Informasi yang Wajib Disediakan dan Diinformasikan Secara Berkala",
              description: null,
              menu_name: "navigasi-kedua",
              provider: "menu_link_content",
              parent: "menu_link_content:531e2cf5-8b86-4afe-80ee-50e4655d239d",
              enabled: true,
              expanded: false,
              resettable: false,
              translatable: true,
              deletable: true,
              route_name: "entity.node.canonical",
              route_parameters: { node: "34207" },
              url:
                "/id/informasi-yang-wajib-disediakan-dan-diumumkan-secara-berkala",
              options: [],
              meta_data: { entity_id: "21" },
              delete_route: {},
              edit_route: {},
            },
            has_children: false,
            depth: 3,
            in_active_trail: false,
            subtree: [],
            count: 1,
          },
          {
            link: {
              weight: "-48",
              title: "Daftar Informasi yang Wajib Diumumkan Dengan Serta Merta",
              description: null,
              menu_name: "navigasi-kedua",
              provider: "menu_link_content",
              parent: "menu_link_content:531e2cf5-8b86-4afe-80ee-50e4655d239d",
              enabled: true,
              expanded: false,
              resettable: false,
              translatable: true,
              deletable: true,
              route_name: "entity.node.canonical",
              route_parameters: { node: "34210" },
              url: "/id/informasi-yang-wajib-diumumkan-secara-serta-merta",
              options: [],
              meta_data: { entity_id: "22" },
              delete_route: {},
              edit_route: {},
            },
            has_children: false,
            depth: 3,
            in_active_trail: false,
            subtree: [],
            count: 1,
          },
          {
            link: {
              weight: "-47",
              title: "Daftar Informasi yang Wajib Tersedia Setiap Saat",
              description: null,
              menu_name: "navigasi-kedua",
              provider: "menu_link_content",
              parent: "menu_link_content:531e2cf5-8b86-4afe-80ee-50e4655d239d",
              enabled: true,
              expanded: false,
              resettable: false,
              translatable: true,
              deletable: true,
              route_name: "entity.node.canonical",
              route_parameters: { node: "34208" },
              url: "/id/informasi-publik-tersedia-setiap-saat",
              options: [],
              meta_data: { entity_id: "23" },
              delete_route: {},
              edit_route: {},
            },
            has_children: false,
            depth: 3,
            in_active_trail: false,
            subtree: [],
            count: 1,
          },
        ],
        count: 4,
      },
      {
        link: {
          weight: "-48",
          title: "Anggaran dan Realisasi Keuangan",
          description: null,
          menu_name: "navigasi-kedua",
          provider: "menu_link_content",
          parent: "menu_link_content:addcb77a-f63f-4755-80cc-fad5c6f33fd8",
          enabled: true,
          expanded: true,
          resettable: false,
          translatable: true,
          deletable: true,
          route_name: "\u003Cfront\u003E",
          route_parameters: [],
          url: "/id",
          options: [],
          meta_data: { entity_id: "45" },
          delete_route: {},
          edit_route: {},
        },
        has_children: true,
        depth: 2,
        in_active_trail: false,
        subtree: [
          {
            link: {
              weight: "-50",
              title: "Daftar Isian Pelaksanaan Anggaran",
              description: null,
              menu_name: "navigasi-kedua",
              provider: "menu_link_content",
              parent: "menu_link_content:f79da8d4-8c68-4635-93cc-1ed16f02b8bb",
              enabled: true,
              expanded: false,
              resettable: false,
              translatable: true,
              deletable: true,
              route_name: "entity.node.canonical",
              route_parameters: { node: "34513" },
              url: "/id/daftar-isian-pelaksanaan-anggaran",
              options: [],
              meta_data: { entity_id: "46" },
              delete_route: {},
              edit_route: {},
            },
            has_children: false,
            depth: 3,
            in_active_trail: false,
            subtree: [],
            count: 1,
          },
          {
            link: {
              weight: "-49",
              title: "Realisasi Belanja",
              description: null,
              menu_name: "navigasi-kedua",
              provider: "menu_link_content",
              parent: "menu_link_content:f79da8d4-8c68-4635-93cc-1ed16f02b8bb",
              enabled: true,
              expanded: false,
              resettable: false,
              translatable: true,
              deletable: true,
              route_name: "entity.node.canonical",
              route_parameters: { node: "34562" },
              url: "/id/realisasi-belanja",
              options: { query: [] },
              meta_data: { entity_id: "50" },
              delete_route: {},
              edit_route: {},
            },
            has_children: false,
            depth: 3,
            in_active_trail: false,
            subtree: [],
            count: 1,
          },
          {
            link: {
              weight: "-48",
              title: "Laporan Keuangan DJP",
              description: null,
              menu_name: "navigasi-kedua",
              provider: "menu_link_content",
              parent: "menu_link_content:f79da8d4-8c68-4635-93cc-1ed16f02b8bb",
              enabled: true,
              expanded: false,
              resettable: false,
              translatable: true,
              deletable: true,
              route_name: "view.laporan_keuangan_page.page_1",
              route_parameters: [],
              url: "/id/keuangan-page",
              options: { query: [] },
              meta_data: { entity_id: "16" },
              delete_route: {},
              edit_route: {},
            },
            has_children: false,
            depth: 3,
            in_active_trail: false,
            subtree: [],
            count: 1,
          },
        ],
        count: 4,
      },
      {
        link: {
          weight: "-47",
          title: "Transparansi Kinerja Ditjen Pajak",
          description: null,
          menu_name: "navigasi-kedua",
          provider: "menu_link_content",
          parent: "menu_link_content:addcb77a-f63f-4755-80cc-fad5c6f33fd8",
          enabled: true,
          expanded: true,
          resettable: false,
          translatable: true,
          deletable: true,
          route_name: "\u003Cfront\u003E",
          route_parameters: [],
          url: "/id",
          options: [],
          meta_data: { entity_id: "47" },
          delete_route: {},
          edit_route: {},
        },
        has_children: true,
        depth: 2,
        in_active_trail: false,
        subtree: [
          {
            link: {
              weight: "-50",
              title: "Rencana Strategis",
              description: null,
              menu_name: "navigasi-kedua",
              provider: "menu_link_content",
              parent: "menu_link_content:666632c7-1917-45ef-8ff5-cf2dc82317c6",
              enabled: true,
              expanded: false,
              resettable: false,
              translatable: true,
              deletable: true,
              route_name: "entity.node.canonical",
              route_parameters: { node: "34216" },
              url: "/id/rencana-strategis-2015-2019-direktorat-jenderal-pajak",
              options: [],
              meta_data: { entity_id: "19" },
              delete_route: {},
              edit_route: {},
            },
            has_children: false,
            depth: 3,
            in_active_trail: false,
            subtree: [],
            count: 1,
          },
          {
            link: {
              weight: "-48",
              title: "Rencana Kerja Ditjen Pajak",
              description: null,
              menu_name: "navigasi-kedua",
              provider: "menu_link_content",
              parent: "menu_link_content:666632c7-1917-45ef-8ff5-cf2dc82317c6",
              enabled: true,
              expanded: false,
              resettable: false,
              translatable: true,
              deletable: true,
              route_name: "entity.node.canonical",
              route_parameters: { node: "34523" },
              url: "/id/rencana-kerja-ditjen-pajak",
              options: [],
              meta_data: { entity_id: "49" },
              delete_route: {},
              edit_route: {},
            },
            has_children: false,
            depth: 3,
            in_active_trail: false,
            subtree: [],
            count: 1,
          },
          {
            link: {
              weight: "-47",
              title: "Laporan Kinerja DJP",
              description: null,
              menu_name: "navigasi-kedua",
              provider: "menu_link_content",
              parent: "menu_link_content:666632c7-1917-45ef-8ff5-cf2dc82317c6",
              enabled: true,
              expanded: false,
              resettable: false,
              translatable: true,
              deletable: true,
              route_name: "view.laporan_kinerja_page.page_1",
              route_parameters: [],
              url: "/id/kinerja-page",
              options: { query: [] },
              meta_data: { entity_id: "17" },
              delete_route: {},
              edit_route: {},
            },
            has_children: false,
            depth: 3,
            in_active_trail: false,
            subtree: [],
            count: 1,
          },
          {
            link: {
              weight: "-46",
              title: "Laporan Tahunan DJP",
              description: null,
              menu_name: "navigasi-kedua",
              provider: "menu_link_content",
              parent: "menu_link_content:666632c7-1917-45ef-8ff5-cf2dc82317c6",
              enabled: true,
              expanded: false,
              resettable: false,
              translatable: true,
              deletable: true,
              route_name: "view.laporan_tahunan_page.page_1",
              route_parameters: [],
              url: "/id/tahunan-page",
              options: { query: [] },
              meta_data: { entity_id: "18" },
              delete_route: {},
              edit_route: {},
            },
            has_children: false,
            depth: 3,
            in_active_trail: false,
            subtree: [],
            count: 1,
          },
          {
            link: {
              weight: "-45",
              title: "Realisasi Pendapatan Pajak",
              description: null,
              menu_name: "navigasi-kedua",
              provider: "menu_link_content",
              parent: "menu_link_content:666632c7-1917-45ef-8ff5-cf2dc82317c6",
              enabled: true,
              expanded: false,
              resettable: false,
              translatable: true,
              deletable: true,
              route_name: "entity.node.canonical",
              route_parameters: { node: "34561" },
              url: "/id/realisasi-pendapatan",
              options: { query: [] },
              meta_data: { entity_id: "51" },
              delete_route: {},
              edit_route: {},
            },
            has_children: false,
            depth: 3,
            in_active_trail: false,
            subtree: [],
            count: 1,
          },
        ],
        count: 6,
      },
      {
        link: {
          weight: "-46",
          title: "Regulasi Informasi Publik",
          description: null,
          menu_name: "navigasi-kedua",
          provider: "menu_link_content",
          parent: "menu_link_content:addcb77a-f63f-4755-80cc-fad5c6f33fd8",
          enabled: true,
          expanded: false,
          resettable: false,
          translatable: true,
          deletable: true,
          route_name: "entity.node.canonical",
          route_parameters: { node: "34206" },
          url: "/id/regulasi-ppid",
          options: [],
          meta_data: { entity_id: "24" },
          delete_route: {},
          edit_route: {},
        },
        has_children: false,
        depth: 2,
        in_active_trail: false,
        subtree: [],
        count: 1,
      },
      {
        link: {
          weight: "0",
          title: "Layanan Akses Informasi Publik",
          description: null,
          menu_name: "navigasi-kedua",
          provider: "menu_link_content",
          parent: "menu_link_content:addcb77a-f63f-4755-80cc-fad5c6f33fd8",
          enabled: true,
          expanded: false,
          resettable: false,
          translatable: true,
          deletable: true,
          route_name: "entity.node.canonical",
          route_parameters: { node: "34516" },
          url: "/id/akses-informasi-publik-melalui-layanan-ppid",
          options: { query: [] },
          meta_data: { entity_id: "53" },
          delete_route: {},
          edit_route: {},
        },
        has_children: false,
        depth: 2,
        in_active_trail: false,
        subtree: [],
        count: 1,
      },
      {
        link: {
          weight: "0",
          title: "Pengaduan Penyalahgunaan Wewenang",
          description: null,
          menu_name: "navigasi-kedua",
          provider: "menu_link_content",
          parent: "menu_link_content:addcb77a-f63f-4755-80cc-fad5c6f33fd8",
          enabled: true,
          expanded: false,
          resettable: false,
          translatable: true,
          deletable: true,
          route_name: "entity.node.canonical",
          route_parameters: { node: "34518" },
          url: "/id/saluran-pengaduan",
          options: { query: [] },
          meta_data: { entity_id: "54" },
          delete_route: {},
          edit_route: {},
        },
        has_children: false,
        depth: 2,
        in_active_trail: false,
        subtree: [],
        count: 1,
      },
    ],
    count: 18,
  },
  {
    link: {
      weight: "0",
      title: "Internasional",
      description: null,
      menu_name: "navigasi-kedua",
      provider: "menu_link_content",
      parent: "",
      enabled: true,
      expanded: true,
      resettable: false,
      translatable: true,
      deletable: true,
      route_name: "\u003Cfront\u003E",
      route_parameters: [],
      url: "/id",
      options: [],
      meta_data: { entity_id: "43" },
      delete_route: {},
      edit_route: {},
    },
    has_children: true,
    depth: 1,
    in_active_trail: false,
    subtree: [
      {
        link: {
          weight: "-50",
          title: "APA dan MAP",
          description: null,
          menu_name: "navigasi-kedua",
          provider: "menu_link_content",
          parent: "menu_link_content:3cc55938-af72-40cd-9396-c96650aaf1ac",
          enabled: true,
          expanded: false,
          resettable: false,
          translatable: true,
          deletable: true,
          route_name: "entity.node.canonical",
          route_parameters: { node: "34204" },
          url: "/id/apa-map",
          options: [],
          meta_data: { entity_id: "44" },
          delete_route: {},
          edit_route: {},
        },
        has_children: false,
        depth: 2,
        in_active_trail: false,
        subtree: [],
        count: 1,
      },
      {
        link: {
          weight: "-49",
          title: "Pertukaran Informasi",
          description: null,
          menu_name: "navigasi-kedua",
          provider: "menu_link_content",
          parent: "menu_link_content:3cc55938-af72-40cd-9396-c96650aaf1ac",
          enabled: true,
          expanded: true,
          resettable: false,
          translatable: true,
          deletable: true,
          route_name: "\u003Cfront\u003E",
          route_parameters: [],
          url: "/id",
          options: [],
          meta_data: { entity_id: "25" },
          delete_route: {},
          edit_route: {},
        },
        has_children: true,
        depth: 2,
        in_active_trail: false,
        subtree: [
          {
            link: {
              weight: "0",
              title: "CbCR",
              description: null,
              menu_name: "navigasi-kedua",
              provider: "menu_link_content",
              parent: "menu_link_content:6dccd689-cfea-4720-9b0b-bfc1b6854a3d",
              enabled: true,
              expanded: true,
              resettable: false,
              translatable: true,
              deletable: true,
              route_name: "entity.node.canonical",
              route_parameters: { node: "34233" },
              url: "/id/cbcr",
              options: [],
              meta_data: { entity_id: "26" },
              delete_route: {},
              edit_route: {},
            },
            has_children: true,
            depth: 3,
            in_active_trail: false,
            subtree: [
              {
                link: {
                  weight: "0",
                  title: "Informasi",
                  description: null,
                  menu_name: "navigasi-kedua",
                  provider: "menu_link_content",
                  parent:
                    "menu_link_content:7a49e9ef-189f-4d3f-8dff-6629bfa28c27",
                  enabled: true,
                  expanded: false,
                  resettable: false,
                  translatable: true,
                  deletable: true,
                  route_name: "entity.node.canonical",
                  route_parameters: { node: "34233" },
                  url: "/id/cbcr",
                  options: [],
                  meta_data: { entity_id: "27" },
                  delete_route: {},
                  edit_route: {},
                },
                has_children: false,
                depth: 4,
                in_active_trail: false,
                subtree: [],
                count: 1,
              },
              {
                link: {
                  weight: "1",
                  title: "Pelaporan",
                  description: null,
                  menu_name: "navigasi-kedua",
                  provider: "menu_link_content",
                  parent:
                    "menu_link_content:7a49e9ef-189f-4d3f-8dff-6629bfa28c27",
                  enabled: true,
                  expanded: false,
                  resettable: false,
                  translatable: true,
                  deletable: true,
                  route_name: "",
                  route_parameters: [],
                  url: "https://e-cbcr.pajak.go.id/",
                  options: { external: true },
                  meta_data: { entity_id: "28" },
                  delete_route: {},
                  edit_route: {},
                },
                has_children: false,
                depth: 4,
                in_active_trail: false,
                subtree: [],
                count: 1,
              },
            ],
            count: 3,
          },
          {
            link: {
              weight: "1",
              title: "EOI",
              description: null,
              menu_name: "navigasi-kedua",
              provider: "menu_link_content",
              parent: "menu_link_content:6dccd689-cfea-4720-9b0b-bfc1b6854a3d",
              enabled: true,
              expanded: true,
              resettable: false,
              translatable: true,
              deletable: true,
              route_name: "entity.node.canonical",
              route_parameters: { node: "34232" },
              url: "/id/laman-exchange-information",
              options: [],
              meta_data: { entity_id: "29" },
              delete_route: {},
              edit_route: {},
            },
            has_children: true,
            depth: 3,
            in_active_trail: false,
            subtree: [
              {
                link: {
                  weight: "-50",
                  title: "Informasi",
                  description: null,
                  menu_name: "navigasi-kedua",
                  provider: "menu_link_content",
                  parent:
                    "menu_link_content:0c5a92bb-2734-4bf9-b164-1c137df3075a",
                  enabled: true,
                  expanded: false,
                  resettable: false,
                  translatable: true,
                  deletable: true,
                  route_name: "entity.node.canonical",
                  route_parameters: { node: "34232" },
                  url: "/id/laman-exchange-information",
                  options: [],
                  meta_data: { entity_id: "30" },
                  delete_route: {},
                  edit_route: {},
                },
                has_children: false,
                depth: 4,
                in_active_trail: false,
                subtree: [],
                count: 1,
              },
              {
                link: {
                  weight: "-49",
                  title: "Pelaporan",
                  description: null,
                  menu_name: "navigasi-kedua",
                  provider: "menu_link_content",
                  parent:
                    "menu_link_content:0c5a92bb-2734-4bf9-b164-1c137df3075a",
                  enabled: true,
                  expanded: false,
                  resettable: false,
                  translatable: true,
                  deletable: true,
                  route_name: "",
                  route_parameters: [],
                  url: "https://eoi.pajak.go.id/",
                  options: { external: true },
                  meta_data: { entity_id: "31" },
                  delete_route: {},
                  edit_route: {},
                },
                has_children: false,
                depth: 4,
                in_active_trail: false,
                subtree: [],
                count: 1,
              },
              {
                link: {
                  weight: "-47",
                  title: "Pendaftaran",
                  description: null,
                  menu_name: "navigasi-kedua",
                  provider: "menu_link_content",
                  parent:
                    "menu_link_content:0c5a92bb-2734-4bf9-b164-1c137df3075a",
                  enabled: true,
                  expanded: false,
                  resettable: false,
                  translatable: true,
                  deletable: true,
                  route_name: "",
                  route_parameters: [],
                  url: "https://eoi.pajak.go.id/",
                  options: { external: true },
                  meta_data: { entity_id: "32" },
                  delete_route: {},
                  edit_route: {},
                },
                has_children: false,
                depth: 4,
                in_active_trail: false,
                subtree: [],
                count: 1,
              },
            ],
            count: 4,
          },
        ],
        count: 8,
      },
      {
        link: {
          weight: "-48",
          title: "Tax Treaty",
          description: null,
          menu_name: "navigasi-kedua",
          provider: "menu_link_content",
          parent: "menu_link_content:3cc55938-af72-40cd-9396-c96650aaf1ac",
          enabled: true,
          expanded: false,
          resettable: false,
          translatable: true,
          deletable: true,
          route_name: "view.tax_treaty.page_1",
          route_parameters: [],
          url: "/id/tax-treaty",
          options: { query: [] },
          meta_data: { entity_id: "10" },
          delete_route: {},
          edit_route: {},
        },
        has_children: false,
        depth: 2,
        in_active_trail: false,
        subtree: [],
        count: 1,
      },
    ],
    count: 11,
  },
];

import Axios from "axios";
import { useState } from "react";

export function useApi(config) {
  return Axios.create(config);
}

export const AXIOS_STATUS = {
  IDLE: 0,
  LOADING: 1,
  ERROR: 2,
  FINISHED: 3,
};

export default function useAxios(config) {
  const [status, setStatus] = useState(AXIOS_STATUS.IDLE);
  const reset = () => setStatus(AXIOS_STATUS.IDLE);
  const api = useApi(config);
  api.interceptors.request.use(
    (config) => {
      setStatus(AXIOS_STATUS.LOADING);
      return config;
    },
    (error) => {
      setStatus(AXIOS_STATUS.ERROR);
      return Promise.reject(error);
    }
  );

  api.interceptors.response.use(
    (response) => {
      setStatus(AXIOS_STATUS.FINISHED);

      return response;
    },
    (error) => {
      setStatus(AXIOS_STATUS.ERROR);
      return Promise.reject(error);
    }
  );

  return [status, setStatus, api, reset];
}

export function useHttpMethod(method, config) {
  const [status, setStatus, api, reset] = useAxios(config);
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const invalidate = () => {
    reset();
    setStatus(AXIOS_STATUS.IDLE);
    setError(null);
    setResponse(null);
  };

  const baseFn = function (url, requestData) {
    return api[method](url, requestData)
      .then((response) => {
        setResponse(response);
      })
      .catch((err) => {
        setError(err);
      });
  };
  return {
    status,
    fetching:
      status === AXIOS_STATUS.LOADING || status === AXIOS_STATUS.FINISHED,
    loading: status === AXIOS_STATUS.LOADING,
    response,
    data: response && response.data,
    error,
    invalidate,
    baseFn,
  };
}

export function useHttpGet(config) {
  return useHttpMethod("get", config);
}

export function useHttpPost(config) {
  return useHttpMethod("post", config);
}

export function useHttpPut(config) {
  return useHttpMethod("put", config);
}

export function useHttpDelete(config) {
  return useHttpMethod("delete", config);
}

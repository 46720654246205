import React, { useState } from "react";
import "./assets/css/app.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import UserContextProvider from "./context/UserContext";
import Djpappcontainer from "./components/djp/DjpAppContainer";
import ErrorPage from "./components/auth/ErrorPage";
import Pagecontainer from "./components/auth/PageContainer";
import Logout from "./components/auth/Logout";

function App() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loginDetails, setLoginDetails] = useState({});

  return (
    <UserContextProvider>
      <Router>
        <Switch>
          <Route exact path="/">
            <Pagecontainer
              isAppMode={true}
              username={username}
              password={password}
              loginDetails={loginDetails}
              setLoginDetails={setLoginDetails}
              setUsername={setUsername}
              setPassword={setPassword}
            ></Pagecontainer>
          </Route>
          <Route exact path="/error/:code">
            <Djpappcontainer isAppMode={true}>
              <ErrorPage />
            </Djpappcontainer>
          </Route>

          <Route exact path="/logout">
            <Logout />
          </Route>
          <Route exact path="/v">
            Version 1.3.0
          </Route>
        </Switch>
      </Router>
    </UserContextProvider>
  );
}

export default App;

import React from "react";
import PropTypes from "prop-types";
import { AiOutlineLoading3Quarters as LoadingIcon } from "react-icons/ai";


const colors = {
  "djp-biru": {
    100: "#263688",
    500: "#212c5f",
    800: "#02275d",
    900: "#070f32",
  },

  "djp-kuning": {
    100: "#ffe804",
    500: "#ffca1b",
    900: "#c89221",
  },

  "djp-abu": {
    100: "#ffffff",
    200: "#f2f2f2",
    500: "#e4e4e4",
    800: "#777777",
    900: "#999999",
  },
};

export function Spinner(props) {
  const iconColor = "text-djp-biru-800 animate-spin";
  let iconStyle = { fontSize: (props.small ? "25px" : "50px" ) };
  iconStyle = props.primary ? iconStyle : {...iconStyle, color: "#FFF"}
  return <LoadingIcon className={iconColor} style={iconStyle} />
}

export function Ellipsis(props) {
  const primaryColor = colors["djp-biru"][800];
  const secondaryColor = colors["djp-kuning"][900];
  const whiteColor = "#fff";

  let color = primaryColor;

  switch (props.variant) {
    case "primary":
      color = primaryColor;
      break;
    case "secondary":
      color = secondaryColor;
      break;
    case "white":
      color = whiteColor;
      break;
    default:
      color = props.color || primaryColor;
      break;
  }

  return (
    <>
      <style
        dangerouslySetInnerHTML={{
          __html: `
        .lds-ellipsis {
            position: relative;
            display: inline-block;
            height: 10px;
            width: 30px;
          }
          .lds-ellipsis div {
            position: absolute;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: ${color};
            animation-timing-function: cubic-bezier(0, 1, 1, 0);
          }
          .lds-ellipsis div:nth-child(1) {
            left: 0px;
            animation: lds-ellipsis1 0.6s infinite;
          }
          .lds-ellipsis div:nth-child(2) {
            left: 0px;
            animation: lds-ellipsis2 0.6s infinite;
          }
          .lds-ellipsis div:nth-child(3) {
            left: 12px;
            animation: lds-ellipsis2 0.6s infinite;
          }
          .lds-ellipsis div:nth-child(4) {
            left: 24px;
            animation: lds-ellipsis3 0.6s infinite;
          }
          @keyframes lds-ellipsis1 {
            0% {
              transform: scale(0);
            }
            100% {
              transform: scale(1);
            }
          }
          @keyframes lds-ellipsis3 {
            0% {
              transform: scale(1);
            }
            100% {
              transform: scale(0);
            }
          }
          @keyframes lds-ellipsis2 {
            0% {
              transform: translate(0, 0);
            }
            100% {
              transform: translate(12px, 0);
            }
          }
          `,
        }}
      />
      <div className={"lds-ellipsis"}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </>
  );
}

Ellipsis.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "white"]),
};

import { useHttpPost } from "./useAxios";

export function useRespondAuth() {
  const request = useHttpPost();
  return [
    request,
    (authorize) => {
      const url = "/api/authorize";
      return request.baseFn(url, {authorize});
    },
  ];
}
import React from "react";
import { useRespondAuth } from "../../services/useRespondAuth";
import Button from "../djp/Button";
import FormControl from "../djp/FormControl";
import { Redirect, useHistory } from "react-router-dom";

export default function Authorize(props) {
  const [{ data }, authorize] = useRespondAuth();
  const history = useHistory();

  const agreeButton = () => {
    authorize("accept");
  };

  const disagreeButton = () => {
    authorize("reject");
  };

  if (data) {
    if (data.data && data.data.client && data.data.client.redirectUri) {
      if (data.status === "success")
        window.location.href =
          data.data.client.redirectUri + "?code=" + data.data.code;
      else {
        let errorType;
        if (data.code === 401) {
          errorType = "access_denied";
        } else if (data.code.startsWith("SERVER")) {
          errorType = "server_error";
        } else {
          errorType = "invalid_request";
        }

        window.location.href =
          data.data.client.redirectUri + "?error=" + errorType;
      }
    } else {
      history.push("/error/" + data.message);
    }
  }

  if (
    props.loginDetails &&
    props.loginDetails.user &&
    props.loginDetails.client &&
    props.loginDetails.twoFa &&
    props.loginDetails.twoFa.status === "1"
  )
    return (
      <div className="container mx-auto">
        <div
          className="md:w-2/3 mx-auto p-10"
          style={{ minWidth: "360px", maxWidth: "540px" }}
        >
          <div className="border rounded-lg shadow-lg p-10 w-full">
            <FormControl>
              <p>
                Anda masuk sebagai
                <div className="font-semibold text-djp-biru-100 underline">
                  {props.loginDetails.user.profil.nama} (NPWP{" "}
                  {props.loginDetails.user.npwp})
                </div>
              </p>
            </FormControl>
            <FormControl>
              <div>
                <h1 className="text-2xl font-semibold ">
                  Permintaan Otorisasi
                </h1>
                <div className="flex mt-4">
                  <img
                    alt={"Logo Aplikasi " + props.loginDetails.client.name}
                    title={"Logo Aplikasi " + props.loginDetails.client.name}
                    src={props.loginDetails.client.logoUri}
                    style={{ width: "64px", height: "64px" }}
                  />
                  <div className="flex flex-col justify-center ml-4">
                    <div className="font-semibold">
                      {props.loginDetails.client.name}
                    </div>
                    <div className="text-xs text-djp-biru-100">
                      <a
                        href={props.loginDetails.client.uri}
                        target="__blank"
                        title="Halaman detil aplikasi"
                      >
                        {props.loginDetails.client.uri}
                      </a>
                    </div>
                    <div className="text-xs">
                      {props.loginDetails.client.tenant.displayName} (
                      {props.loginDetails.client.tenant.tenantName})
                    </div>
                  </div>
                </div>
                <div className="my-4">
                  Aplikasi ini ingin
                  <ul className="list-disc list-inside">
                    {props.loginDetails.scopes.map((scope) => (
                      <li>{scope.label}</li>
                    ))}
                  </ul>
                </div>
                <p>
                  Dengan mengklik setuju, anda mengizinkan aplikasi ini untuk
                  mengakses data anda sesuai dengan syarat dan ketentuan dan
                  pernyataan privasi. DJP tidak dapat digugat dalam bentuk
                  apapun terkait pemberian akses data anda ke aplikasi yang anda
                  telah berikan izin.
                </p>
              </div>
            </FormControl>
            <FormControl>
              <Button primary appendClass="" onClick={agreeButton}>
                Setuju
              </Button>
              <Button appendClass="" silent onClick={disagreeButton}>
                Tidak setuju
              </Button>
            </FormControl>
          </div>{" "}
          {props.DEBUG && (
            <div className="border p-4">
              DEBUG:
              <div className="whitespace-pre-wrap">
                {JSON.stringify(props.loginDetails, null, 2)}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  else if (
    props.loginDetails &&
    props.loginDetails &&
    props.loginDetails.twoFa &&
    props.loginDetails.twoFa.status === "0"
  ) {
    return <Redirect to="/two-factor-auth" />;
  } else return <Redirect to="/" />;
}

import React, { useState, createContext } from "react";

export const UserContext = createContext();

function UserContextProvider(props) {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);

  return (
    <UserContext.Provider value={{ user, setUser, token, setToken }}>
      {props.children}
    </UserContext.Provider>
  );
}

export default UserContextProvider;

import React, { useEffect } from "react";
import { Redirect } from "react-router";
import { useLogout } from "../../services/useAuth";

export default function Logout(props) {
  // TODO: handle error and data response
  const [{ data }, logout] = useLogout();

  useEffect(() => {
    logout();
    // eslint-disable-next-line
  }, []);
  if (data) return <Redirect to="/" />;
  else return null;
}

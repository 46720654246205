import React, { useEffect, useState } from "react";
import { Redirect } from "react-router";
import {
  useComplete2FaToken,
  useRequest2FaToken,
} from "../../services/use2FaToken";
import Button from "../djp/Button";
import FormControl from "../djp/FormControl";
import Input from "../djp/Input";
import { Spinner } from "../djp/LoadingIndicator";

const availableMethods = [
  { id: 1, name: "email", label: "Kirim kode verifikasi melalui email" },
  //{ id: 2, name: "sms", label: "Kirim kode verifikasi melalui SMS" },
  //{ id: 3, name: "totp", label: "Kirim kode verifikasi melalui Google Auth" },
];

const delay = 30;

function ButtonRerequest({ request, method, setResetTime, seconds }) {
  const [timer, setTimer] = useState(seconds);

  useEffect(() => {
    let interval = null;
    interval = setInterval(() => {
      setTimer((timer) => timer - 1000);
    }, 1000);
    return () => clearInterval(interval);
  }, [timer]);

  if (timer < 0)
    return (
      <Button
        appendClass="w-full"
        silent
        onClick={(e) => {
          setTimer(delay * 1000);
          request(Number(method));
        }}
      >
        Kirim ulang
      </Button>
    );
  else
    return (
      <Button appendClass="w-full" silent onClick={(e) => false}>
        Anda bisa meminta ulang token setelah {Math.round(timer / 1000)} detik
      </Button>
    );
}

function parseMethod(methodString) {
  const methods = methodString.split("");
  methods.reverse();
  return methods.map(Number);
}

function Method({ handleSelectMethod, label, value, checked }) {
  return (
    <li className="my-4">
      <input
        type="radio"
        value={value}
        name="method"
        onChange={handleSelectMethod}
        checked={checked}
      />
      <label
        className="ml-2"
        onClick={() => handleSelectMethod({ target: { value } })}
      >
        {label}
      </label>
    </li>
  );
}

export default function TwoFactorAuth(props) {
  const [method, setMethod] = useState("1");
  const { setLoginDetails } = props;
  const [{ data: req2faData }, request] = useRequest2FaToken();
  const [token, setToken] = useState("");
  const [
    { data: complete2faData, loading: complete2faLoading },
    complete2fa,
  ] = useComplete2FaToken();
  const [resetTime, setResetTime] = useState(Date.now() + delay * 1000);
  const complete2faSuccess =
    complete2faData &&
    complete2faData.status === "success" &&
    complete2faData.code === "2FA_SUCCESS";

  useEffect(() => {
    if (complete2faSuccess) {
      setLoginDetails((data) => ({
        ...data,
        twoFa: { ...data.twoFa, status: "1" },
      }));
    }
    // eslint-disable-next-line
  }, [complete2faSuccess]);

  // TODO: If password is empty, focus instead of canceling
  function keyPress(e, cb, keyCode = 13) {
    if (e.keyCode === keyCode) {
      cb(e);
    }
  }

  if (req2faData) {
    if (
      complete2faData &&
      complete2faData.status === "fail" &&
      (complete2faData.code === "INVALID_CLIENT_DATA" ||
        complete2faData.code === "OAUTH2_EXPIRED_REQUEST")
    ) {
      return <Redirect to={"/error/" + complete2faData.code} />;
    }

    if (
      complete2faData &&
      complete2faData.status === "fail" &&
      complete2faData.code === "2FA_TOKEN_EXPIRED"
    ) {
      return (
        <div className="container mx-auto">
          <div
            className="md:w-2/3 mx-auto p-10"
            style={{ minWidth: "360px", maxWidth: "540px" }}
          >
            <div className="border rounded-lg shadow-lg p-10 w-full">
              <h1 className="text-2xl font-semibold ">Verifikasi Token</h1>
              <FormControl>
                <p>
                  Token yang diminta sudah tidak valid. Silakan meminta token
                  kembali.
                </p>
                <Button appendClass="" silent>
                  Kirim ulang
                </Button>
              </FormControl>
            </div>
          </div>
        </div>
      );
    }

    if (req2faData.status === "fail") {
      return <Redirect to="/" />;
    }

    return (
      <div className="container mx-auto">
        <div
          className="md:w-2/3 mx-auto p-10"
          style={{ minWidth: "360px", maxWidth: "540px" }}
        >
          <div className="border rounded-lg shadow-lg p-10 w-full">
            <h1 className="text-2xl font-semibold ">Verifikasi Token</h1>
            <FormControl>
              <p>
                Kami telah mengirimkan token ke alamat email{" "}
                {(props.loginDetails &&
                  props.loginDetails.user &&
                  props.loginDetails.user.email) ||
                  "anda"}
                .
              </p>
              <p>
                No transaksi:
                <br />
                <span className="text-xs">{req2faData.trxId}</span>
              </p>
            </FormControl>
            <FormControl>
              <Input
                disabled={complete2faLoading}
                error={complete2faData && complete2faData.status === "fail"}
                helperText={
                  complete2faData &&
                  complete2faData &&
                  complete2faData.status === "fail" &&
                  "Token gagal diverifikasi karena token yang diinput salah."
                }
                fullWidth
                label="Token"
                type="text"
                onChange={(e) => setToken(e.target.value.split(" ").join(""))}
                value={token}
              />
            </FormControl>
            <FormControl>
              <Button
                disabled={complete2faLoading}
                primary
                appendClass="w-full"
                onClick={(e) => complete2fa(token)}
              >
                {complete2faLoading ? (
                  <div className="flex justify-center">
                    <Spinner small />
                  </div>
                ) : (
                  "Verifikasi Token"
                )}
              </Button>
              <ButtonRerequest
                setResetTime={setResetTime}
                request={request}
                method={method}
                seconds={resetTime - Date.now()}
              />
            </FormControl>
          </div>
        </div>
      </div>
    );
  }

  if (props.loginDetails && props.loginDetails.client) {
    const options = parseMethod(
      Number(props.loginDetails.twoFa.methods).toString(2)
    );
    return (
      <div className="container mx-auto">
        <div
          className="md:w-2/3 mx-auto p-10"
          style={{ minWidth: "360px", maxWidth: "540px" }}
        >
          <div className="border rounded-lg shadow-lg p-10 w-full">
            <h1 className="text-2xl font-semibold my-2">Verifikasi Token</h1>
            <p className="my-2">
              Demi keamanan anda, anda harus melakukan verifikasi token sebelum
              dapat melanjutkan ke langkah selanjutnya. Silakan pilih metode
              pengiriman token.
            </p>
            <ul className="my-4">
              {options[0] === 1
                ? availableMethods.map((row) => {
                    return (
                      <Method
                        handleSelectMethod={(e) => setMethod(e.target.value)}
                        label={row.label}
                        value={row.id}
                        checked={Number(method) === Number(row.id)}
                      />
                    );
                    // return <li><input type="radio" value={index} name="method" onChange={e => setMethod(e.target.value)} />{row.label}</li>;
                  })
                : options.map((row, index) => {
                    if (index > 0 && row === 1)
                      return (
                        <Method
                          handleSelectMethod={(e) => setMethod(e.target.value)}
                          label={
                            availableMethods.find(
                              (method) => method.id === index
                            ).label
                          }
                          value={index}
                          checked={Number(method) === Number(index)}
                        />
                      );
                    else return null;
                  })}
            </ul>
            <FormControl>
              <Button
                primary
                appendClass=""
                onClick={(e) => {
                  request(Number(method));
                }}
              >
                Kirim
              </Button>
            </FormControl>
          </div>
        </div>
      </div>
    );
  }
  return null;
}

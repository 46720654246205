import React from "react";

export default function TabNav(props) {
  const classNames = {
    default: ["px-4 py-3 inline-block pr-10 font-semibold text-lg"],
  };
  let style = {};

  if (props.uppercase) {
    classNames.default.push("uppercase");
  }

  if (props.active) {
    classNames.default.push("border border-djp-abu-500 bg-djp-abu-500 text-djp-abu-800");
    style = {
      boxShadow: "inset 3px 5px 10px #f2f2f2",
      borderRadius: "10px 50px 0 0",
    };
  } else {
    classNames.default.push("bg-djp-biru-800 text-white hover:bg-djp-biru-900");
    style = {
      borderRadius: "10px 50px 0 0",
    };
  }

  let Component = (props) => <a {...props}>{props.children}</a>;

  if (props.component)
    Component = (props) => (
      <props.component {...props}>{props.children}</props.component>
    );

  return (
    <Component {...props}>
      <div className={classNames.default.join(" ")} style={style}>
        {props.label}
      </div>
    </Component>
  );
}

import React, { useState } from "react";
import search from "../../assets/image/icon/search.svg";
import { BiSearch as SearchIcon } from "react-icons/bi";

export default function MobileSearchForm(props) {
  const [menuOpen, setMenuOpen] = useState(false);
  return (
    <>
      <div className="flex lg:hidden justify-items-center items-center mx-3">
        <button
          className="focus:outline-none text-djp-biru-500 text-2xl py-1 font-bold relative"
          onClick={(e) => {
            setMenuOpen((state) => !state);
          }}
        >
          <SearchIcon />
        </button>
        {menuOpen && (
          <div className="search-container absolute block bg-djp-biru-500 rounded py-3 px-2 z-50">
            <div className="flex justify-items-center items-center">
              <div className="mainmenu-search">
                <form
                  action="https://pajak.go.id/id/search/node"
                  method="get"
                  className="mainmenu-search__form"
                >
                  <div className="input-group text-sm">
                    <input
                      type="text"
                      className="mainmenu-search__input"
                      name="keys"
                      placeholder="Pencarian..."
                      autoComplete="off"
                    />
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <img src={search} alt="search" className="search" />
                      </span>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="hidden lg:flex justify-items-center items-center">
        <div className="mainmenu-search">
          <form
            action="https://pajak.go.id/id/search/node"
            method="get"
            className="mainmenu-search__form"
          >
            <div className="input-group">
              <input
                type="text"
                className="mainmenu-search__input"
                name="keys"
                placeholder="Pencarian..."
                autoComplete="off"
              />
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <img src={search} alt="search" className="search" />
                </span>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

import { useHttpPost, useHttpGet } from "./useAxios";

export function useLogin() {
  const request = useHttpPost();
  return [
    request,
    (npwp, password) => {
      const url = "/api/login";
      return request.baseFn(url, { npwp: npwp.replace(/[.-]/g, ''), password });
    },
  ];
}

export function useLogout() {
  const request = useHttpGet();
  return [
    request,
    () => {
      const url = "/api/logout";
      return request.baseFn(url);
    },
  ];
}

export function useExchangeCookie() {
  const request = useHttpPost();
  return [
    request,
    () => {
      const url = "/api/data";
      return request.baseFn(url);
    },
  ];
}
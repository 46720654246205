import React, { useEffect } from "react";
import { useExchangeCookie } from "../../services/useAuth";
import Djpappcontainer from "../djp/DjpAppContainer";
import { Spinner } from "../djp/LoadingIndicator";
import { BiError as FailIcon } from "react-icons/bi";

import TwoFactorAuth from "./TwoFactorAuth";
import Login from "./Login";
import Authorize from "./Authorize";

export default function Pagecontainer(props) {
  const {
    setLoginDetails,
    loginDetails,
    username,
    password,
    setUsername,
    setPassword,
  } = props;
  const [{ data, error }, exchange] = useExchangeCookie();
  const loadingTextArray = [
    "Memuat data",
    "Menyiapkan halaman",
    "Mengatur konfigurasi",
  ];

  const loadingText = loadingTextArray[0];

  const dataFetched = data && data.data;
  const authenticated = loginDetails && loginDetails.user;
  const client = loginDetails && loginDetails.client;
  const twoFa = loginDetails && loginDetails.twoFa;
  const twoFaCompleted =
    twoFa && (twoFa.methods === "0" || twoFa.status === "1");
  const twoFaRequired = twoFa && twoFa.methods !== "0" && twoFa.status !== "1";

  useEffect(() => {
    exchange();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (dataFetched && setLoginDetails) setLoginDetails(dataFetched);
  }, [dataFetched, setLoginDetails]);

  if (data && data.status === "success") {
    if (authenticated) {
      if (twoFaCompleted) {
        return (
          <Djpappcontainer appMode={false} user={props.loginDetails.user}>
            <Authorize
              loginDetails={loginDetails}
              setLoginDetails={setLoginDetails}
            />
          </Djpappcontainer>
        );
      }
      if (client && twoFaRequired) {
        return (
          <Djpappcontainer appMode={false} user={props.loginDetails.user}>
            <TwoFactorAuth
              loginDetails={loginDetails}
              setLoginDetails={setLoginDetails}
            />
          </Djpappcontainer>
        );
      }
      if (!client) {
        window.location.href = "https://djponline.pajak.go.id/";
        return null;
      }
    } else {
      return (
        <Djpappcontainer appMode={false} user={props.loginDetails.user}>
          <Login
            username={username}
            password={password}
            loginDetails={loginDetails}
            setLoginDetails={setLoginDetails}
            setUsername={setUsername}
            setPassword={setPassword}
          />
        </Djpappcontainer>
      );
    }
  } else if ((data && data.status !== "success") || error) {
    return (
      <Djpappcontainer appMode={false}>
        <div className="flex flex-col items-center justify-center w-full h-20 my-20">
          <div>
            <FailIcon style={{ fontSize: "50px" }} />
          </div>
          <div className="mt-5">
            Gagal memuat data. Silakan refresh (tekan F5) halaman ini.
          </div>
        </div>
      </Djpappcontainer>
    );
  }

  return (
    <Djpappcontainer appMode={false}>
      <div className="flex flex-col items-center justify-center w-full h-20 my-20">
        <div>
          <Spinner primary />
        </div>
        <div className="mt-5">{loadingText}</div>
      </div>
    </Djpappcontainer>
  );
}

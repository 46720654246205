import React, { useEffect, useRef, useState } from "react";
import { FaUser as UserIcon } from "react-icons/fa";
import { RiAccountBoxLine as AccountIcon } from "react-icons/ri";
import { FiChevronRight as ChevronRightIcon } from "react-icons/fi";

function useClickOutsideDom(ref, onClickOutsideDom) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutsideDom && onClickOutsideDom();
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, onClickOutsideDom]);
}

export default function HeaderUserInfo(props) {
  const [menuOpen, setMenuOpen] = useState(false);
  const ref = useRef(null);
  useClickOutsideDom(ref, () => setMenuOpen(false));
  return (
    <div className="relative hidden lg:flex items-center">
      <button
        type="button"
        className="btn-user relative focus:outline-none"
        onClick={(e) => {
          setMenuOpen((state) => !state);
        }}
      >
        <div className="flex items-center">
          <span className="font-bold text-djp-kuning-500 mr-2">Halo,</span>
          <span className="font-bold text-djp-biru-800">{props.user.nama}</span>
          <span className="account icon rounded ml-2 text-lg flex items-center justify-center">
            <UserIcon />
          </span>
        </div>
      </button>
      {menuOpen && (
        <div
          className="account-container absolute block bg-white right-0 "
          ref={ref}
        >
          <div className="account-top-part flex items-center bg-cover bg-no-repeat">
            <span className="icon-top-part rounded ml-2 text-3xl flex items-center justify-center">
              <UserIcon />
            </span>
            <span className="name-top-part">{props.user.nama}</span>
          </div>
          <div>
            <a
              href="https://account.pajak.go.id/profil"
              className="account-mid-part flex items-center hover:bg-gray-100"
            >
              <div className="icon-mid-part rounded text-2xl flex items-center justify-center">
                <AccountIcon />
              </div>
              <div
                className="flex flex-col items-start"
                style={{ minWidth: "17rem" }}
              >
                <div
                  className="font-medium"
                  style={{
                    color: "#6c7293",
                  }}
                >
                  Profil Saya
                </div>
                <div className="font-light" style={{ color: "#a7abc3" }}>
                  Pengaturan akun dan lainnya
                </div>
              </div>
              <div style={{ color: "#a7abc3" }}>
                <ChevronRightIcon />
              </div>
            </a>
            <div
              className="flex items-center"
              style={{ padding: "22px 21px", borderTop: "1px solid #f7f8fa" }}
            >
              <a
                href="/logout"
                className="btn-logout hover:text-white hover:bg-indigo-700"
              >
                Keluar
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

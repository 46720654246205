import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useLogin } from "../../services/useAuth";
import Button from "../djp/Button";
import FormControl from "../djp/FormControl";
import Input from "../djp/Input";
import { Spinner } from "../djp/LoadingIndicator";

export default function Login(props) {
  const [error, setError] = useState("");
  const history = useHistory();
  const [{ loading, data }, login] = useLogin();

  useEffect(() => {
    if (data && data.status === "success") {
      props.setLoginDetails(data.data);
    }

    if (data && data.status !== "success") {
      if (data.code === "AUTH_FAIL") {
        setError("ok");
      } else {
        const ref =
          data.data && data.data.ref
            ? "?ref=" + data.data.ref.toString("base64")
            : "";
        history.push("/error/" + data.code.toString().toLowerCase() + ref);
      }
    }
    // eslint-disable-next-line
  }, [data, setError]);

  const doLogin = () => {
    login(props.username, props.password);
  };

  // TODO: If password is empty, focus instead of canceling
  function keyPress(e, cb, keyCode = 13) {
    if (e.keyCode === keyCode) {
      cb(e);
    }
  }

  return (
    <div className="container mx-auto">
      <div
        className="md:w-2/3 mx-auto p-10"
        style={{ minWidth: "360px", maxWidth: "540px" }}
      >
        <div className="border rounded-lg shadow-lg p-10 w-full">
          <h1 className="text-2xl font-semibold ">Login</h1>
          <FormControl>
            {error && (
              <div className="bg-red-200 border border-red-600 p-2">
                Tidak berhasil login. Silakan cek kembali NPWP dan password
                anda.
              </div>
            )}
          </FormControl>
          <FormControl>
            <Input
              disabled={loading}
              fullWidth
              error={error}
              label="Nomor Pokok Wajib Pajak"
              mask="npwp"
              value={props.username}
              onChange={(e) => {
                props.setUsername(e.target.value);
              }}
              onKeyDown={(e) =>
                keyPress(e, (e) => {
                  if (props.password && props.username) doLogin();
                })
              }
            />
          </FormControl>
          <FormControl>
            <Input
              fullWidth
              error={error}
              disabled={loading}
              label="Password"
              type="password"
              value={props.password}
              onChange={(e) => {
                props.setPassword(e.target.value);
              }}
              onKeyDown={(e) =>
                keyPress(e, (e) => {
                  if (props.password && props.username) doLogin();
                })
              }
            />
          </FormControl>
          <FormControl>
            <Button
              primary
              appendClass="w-full"
              onClick={doLogin}
              disabled={loading}
            >
              {loading ? (
                <div className="flex justify-center">
                  <Spinner small />
                </div>
              ) : (
                "Login"
              )}
            </Button>
          </FormControl>
        </div>
      </div>
    </div>
  );
}

import { useHttpPost } from "./useAxios";

export function useRequest2FaToken() {
  const request = useHttpPost();
  return [
    request,
    (method) => {
      const url = "/api/request2fa";
      return request.baseFn(url, { method });
    },
  ];
}


export function useComplete2FaToken() {
  const request = useHttpPost();
  return [
    request,
    (token) => {
      const url = "/api/complete2fa";
      return request.baseFn(url, { token });
    },
  ];
}